var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('p',{staticClass:"mb-4"},[_vm._v(" Two students will be working together diluting concentrated acid for their experiment. Before beginning, they think about the RAMP acronym and quickly assess the risk of their planned procedure. Choose the best assessment for each letter. ")]),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"300px"},attrs:{"items":_vm.optionsQ1,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question1Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question1Value", $$v)},expression:"inputs.question1Value"}}),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"300px"},attrs:{"items":_vm.optionsQ2,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question2Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question2Value", $$v)},expression:"inputs.question2Value"}}),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"300px"},attrs:{"items":_vm.optionsQ3,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question3Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question3Value", $$v)},expression:"inputs.question3Value"}}),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"300px"},attrs:{"items":_vm.optionsQ4,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question4Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question4Value", $$v)},expression:"inputs.question4Value"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }