<template>
  <div>
    <v-container>
      <p class="mb-4">
        Two students will be working together diluting concentrated acid for their experiment.
        Before beginning, they think about the RAMP acronym and quickly assess the risk of their
        planned procedure. Choose the best assessment for each letter.
      </p>

      <v-select
        v-model="inputs.question1Value"
        :items="optionsQ1"
        class="d-sm-inline-block ml-3 mr-3"
        style="width: 300px"
        item-text="text"
        item-value="value"
        label="Select an option:"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <v-select
        v-model="inputs.question2Value"
        :items="optionsQ2"
        class="d-sm-inline-block ml-3 mr-3"
        style="width: 300px"
        item-text="text"
        item-value="value"
        label="Select an option:"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <v-select
        v-model="inputs.question3Value"
        :items="optionsQ3"
        class="d-sm-inline-block ml-3 mr-3"
        style="width: 300px"
        item-text="text"
        item-value="value"
        label="Select an option:"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <v-select
        v-model="inputs.question4Value"
        :items="optionsQ4"
        class="d-sm-inline-block ml-3 mr-3"
        style="width: 300px"
        item-text="text"
        item-value="value"
        label="Select an option:"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>
    </v-container>
  </div>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';

export default {
  name: 'UCIrvineQuiz1S1Q11',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question1Value: null,
        question2Value: null,
        question3Value: null,
        question4Value: null,
      },
      optionsQ1: [
        {
          text: 'Remember to wear goggles',
          value: 'answer2',
        },
        {
          text: 'Review laboratory guidelines',
          value: 'answer3',
        },
        {
          text: 'Recognize the hazard of acid to the eyes',
          value: 'answer1',
        },
      ],
      optionsQ2: [
        {
          text: 'Access the eyewash',
          value: 'answer2',
        },
        {
          text: 'Allocate enough time',
          value: 'answer3',
        },
        {
          text: 'Assess procedure, their knowledge, & exposure probability',
          value: 'answer1',
        },
      ],
      optionsQ3: [
        {
          text: 'Minimize risk of exposure by wearing goggles',
          value: 'answer1',
        },
        {
          text: 'Maximize knowledge of the procedure',
          value: 'answer2',
        },
        {
          text: 'Moderate eye exposure with safety glasses',
          value: 'answer3',
        },
      ],
      optionsQ4: [
        {
          text: 'Protect partner with goggles',
          value: 'answer2',
        },
        {
          text: 'Prepare for hazards by finding references',
          value: 'answer3',
        },
        {
          text: 'Prepare for emergencies by locating eye wash',
          value: 'answer1',
        },
      ],
    };
  },
};
</script>
